import React, { Component } from 'react';
import MemberMenu from './MemberMenu';
import ViewMemberDetails from './ViewMemberDetails';
import ApiKey from './ApiKey';
import MemberEditDetails from './MemberEditDetails';
import MemberContactUs from './MemberContactUs';
import Invoices from './Invoices';
import * as api from "../../db/api";



class MemberDashboard extends Component {
    state={
        active:"userdetails",
        currency:"",
        invoices:[]
    }

changeWindow=(active)=>{
    this.setState({
        active,
    })
}

updateCurrency=(currency)=>{
    this.setState({
        currency,
    })
}

componentDidMount=()=>{
api.getInvoices(this.props.user.id).then(res => {
        this.setState({
          invoices: res.data.invoices,
        })
    });
}


    render() {
        let {active} = this.state;
        return (
            <div className="member-content">
                <MemberMenu changeWindow={this.changeWindow} active={active} logUserOutMembersArea={this.props.logUserOutMembersArea}/>
                {active === "userdetails" && <ViewMemberDetails user={this.props.user} updateUser={this.props.updateUser} updateCurrency={this.updateCurrency}/>}
                {active === "api" && <ApiKey user={this.props.user}/>}
                {active === "contact" && <MemberContactUs user={this.props.user}/>}
                {active === "edit" && <MemberEditDetails updateUser={this.props.updateUser} user={this.props.user}/>}
                {active === "invoices" && <Invoices user={this.props.user} invoices={this.state.invoices}/>}
            </div>
        );
    }
}

export default MemberDashboard;