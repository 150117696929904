import React from 'react';

const Loader = () => {
  return (
    <div className="loadcontainer">
    
    <div className="bar">
    </div>
    </div>
  );
};

export default Loader;