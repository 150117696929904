import React, { Component } from "react";
import Loader from "../Loader";
import { Link } from "react-router-dom";

class SelectPlanForm extends Component {
  componentDidMount = () => {
    this.setState({});
  };

  render() {
    let { currency, defaultcurrency } = this.props;
    console.log(this.props.defaultcurrency);
    return (
      <form
        onSubmit={e => {
          this.props.handleSubmit(e);
        }}
      >
        <h2>{this.props.message}</h2>
        <fieldset className="currency">
          <input
            className="currency-choice"
            type="radio"
            checked={currency === "gbp" || !currency}
            name="currency"
            id="gbp-radio"
            value="gbp"
            onChange={e => this.props.handleChange(e)}
          />
          {(defaultcurrency === "gbp" || !defaultcurrency) && (
            <label htmlFor="gbp-radio">GBP (£)</label>
          )}

          <input
            className="currency-choice"
            type="radio"
            checked={currency === "usd"}
            name="currency"
            id="usd-radio"
            value="usd"
            onChange={e => this.props.handleChange(e)}
          />
          {(defaultcurrency === "usd" || !defaultcurrency) && (
            <label htmlFor="usd-radio">USD ($)</label>
          )}

          <input
            className="currency-choice"
            type="radio"
            checked={currency === "aud"}
            name="currency"
            id="aud-radio"
            value="aud"
            onChange={e => this.props.handleChange(e)}
          />

          {(defaultcurrency === "aud" || !defaultcurrency) && (
            <label htmlFor="aud-radio">AUD ($)</label>
          )}

          <input
            className="currency-choice"
            type="radio"
            checked={currency === "eur"}
            name="currency"
            id="eur-radio"
            value="eur"
            onChange={e => this.props.handleChange(e)}
          />

          {(defaultcurrency === "eur" || !defaultcurrency) && (
            <label htmlFor="eur-radio">EUR (€)</label>
          )}
        </fieldset>

        <fieldset className="plans-radio">
          <div className="grid-container">
            {this.props.plans
              .filter(plan => {
                return (
                  plan.currency ===
                    (this.props.defaultcurrency || this.props.currency) ||
                  plan.currency === "na"
                );
              })
              .map(plan => {
                let id = Number(plan.id);
                return (
                  <div className="plan-option" key={id}>
                    <input
                      type="radio"
                      name="apiOption"
                      value={id}
                      checked={this.props.apiOption === id}
                      onChange={this.props.handleChange}
                      id={`plan-${id}`}
                    />
                    <label
                      key={`${id}+${plan.name}`}
                      className={`api-choice-container ${
                        this.props.existingApiOption === id
                          ? "current-plan"
                          : undefined
                      }`}
                      htmlFor={`plan-${id}`}
                    >
                      <span className="plan-head">{`${plan.name}`}</span>
                      <span className="plan-calls">
                        {`${plan.monthly_limit} Monthly API calls`
                          // .replace(/(?<!\.\d+)\B(?=(\d{3})+\b)/g, " ")
                          // .replace(/(?<=\.(\d{3})+)\B/g, " ")
                          }
                      </span>
                      <span className="plan-cost">
                        {plan.currency === "na"
                          ? `Free`
                          : `${plan.currency_symbol}${plan.cost} per month`}
                      </span>
                    </label>
                  </div>
                );
              })}
          </div>
        </fieldset>
        <h5>
          By continuing, you are agreeing to abide by our Terms and Conditions
          which can be viewed{" "}
          <Link to={`/api-terms-conditions`} target="_blank">
            here
          </Link>
        </h5>
        {this.props.loading ? (
          <Loader />
        ) : (
          <div className="button-container">
            {this.props.toggleBox && (
              <button
                onClick={this.props.toggleBox}
                className="button secondary"
                type="button"
              >
                Cancel
                <img src="/images/close.svg" alt="tick" height="14" />
              </button>
            )}
            <button
              disabled={this.props.apiOption === this.props.existingApiOption}
              className="button"
              type="submit"
            >
              {this.props.apiOption === 1 ? (
                "Sign Up To Free Plan"
              ) : this.props.existingApiOption === 1 ? (
                <p className="icon-checkout-red">Go To Checkout</p>
              ) : (
                <div>
                  Continue{" "}
                  <img
                    src="/images/right-arrow-white.svg"
                    alt="right arrow"
                    height="16"
                  />
                </div>
              )}
            </button>
          </div>
        )}
      </form>
    );
  }
}

export default SelectPlanForm;
