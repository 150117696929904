import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Pdf from "../../documents/certificate.pdf";

class CarbonOffset extends Component {
  render() {
    return (
      <div className="blurb carbon-offset">
        <Helmet>
          <title>{`Carbon Offsetting | Easy PNR Converter`}</title>
          <meta
            name="description"
            content="PNR Converter is now a Carbon Neutral Website. We give back to several sustainable projects to offset the carbon used by our servers, development team and even our users!"
          />
        </Helmet>
        <div className="carbon-header"></div>
        <div className="carbon-body">
          <h1>PNR Converter is now Carbon Neutral!</h1>
          <p>
            Not many people know, but the internet is responsible for just as
            much greenhouse gas as the aviation industry! With increasing
            publicity highlighting the importance of caring for our environment
            we’re aiming to become as environmentally conscious as possible.
            Starting in October 2019 we donate monthly to projects around the
            world that help reduce the carbon in the atmosphere. The projects
            run by the QAS Approved carbonfootprint.com range from providing
            fuel efficient low carbon stoves to women in Darfur to fixing broken
            bore holes in Uganda, saving the need to boil collected water. A
            full list of projects can be seen{" "}
            <a href="https://www.carbonfootprint.com/carbonoffsetprojects.html">
              here
            </a>{" "}
            We are currently offsetting all the carbon generated by
            PNRConverter.com, this includes the electricity used by our
            development team, the electricity to power our servers, and also the
            electricity our users (you guys!) use when using PNR Converter!
            Coming shortly we will be providing the option for travel agents and
            their customers to carbon offset their flights directly from PNR
            Converter! Watch this space.
          </p>
          <p>
            Our Latest certificate of carbon offsetting can be found{" "}
            <a href={Pdf} target="_blank" rel="noopener noreferrer">
              Here!
            </a>
          </p>
        </div>
      </div>
    );
  }
}

export default CarbonOffset;
