import React, { Component } from "react";
import Translate from "../translations/Translate";
import translateFunc from "../translations/TranslateFunction";

class FormEntry extends Component {
  render() {
    const placeHolder = translateFunc(
      this.props.value,
      "submitbox.copy-and-paste"
    );
    const optionalPlaceHolder = translateFunc(
      this.props.value,
      "fare.optional"
    );
    const { setInput, input, fare, tax, fee, fareCurrency, pasteIntoBox, adtQty } = this.props;
    return (
      <div className="input-holder">
        <div className="inputArea">
          <textarea
            className="dataInputChildBox"
            name="input"
            // id="xx"
            cols="30"
            rows="10"
            placeholder={placeHolder}
            value={input}
            onChange={e => setInput(e)}
            onPaste={()=>pasteIntoBox()}
          />

          <button className="dataInputChildBox button" type="submit">
            <h3>
              <Translate string={"submitbox.convert"} />
            </h3>
            <img
              src="/images/icons/paper-plane.svg"
              height="48"
              alt="paper plane"
            />
          </button>
        </div>
        <div className="financials">
          <label htmlFor="currency-box">
            <p>Currency:</p>
            <select id="currency-box" name="fareCurrency" onChange={e => setInput(e)} value={fareCurrency}>
              <option value="$">$</option>
              <option value="£">£</option>
              <option value="€">€</option>
              <option value="CA$">CA$</option>
              <option value="CHF">CHF</option>
              <option value="ft">ft</option>
              <option value="HK$">HK$</option>
              <option value="kr">kr</option>
              <option value="kn">kn</option>
              <option value="Ksh">Ksh</option>
              <option value="lei">lei</option>
              <option value="QAR">QAR</option>
              <option value="R">R</option>
              <option value="RM">RM</option>
              <option value="Rp">Rp</option>
              <option value="Rs">Rs</option>
              <option value="R$">R$</option>
              <option value="S/.">S/.</option>
              <option value="zł">zł</option>
              <option value="¥">¥</option>
              <option value="₾">₾</option>
              <option value="₽">₽</option>
              <option value="₺">₺</option>
              <option value="₴">₴</option>
              <option value="₪">₪</option>
              <option value=".د.م">.د.م</option>
              <option value="₦">₦</option>
              <option value="₹">₹</option>
              <option value="₱">₱</option>
              <option value="₩">₩</option>
              <option value="฿">฿</option>
              <option value="₫">₫</option>
            </select>
          </label>
          <div className="fare-inputs">

          <label htmlFor="fare-box">
            <p><Translate string={"fare.fare"} />:</p>
          <select name="adtQty" id="adt-fare-qty" value={adtQty} onChange={e => setInput(e)}>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
          </select>
            <input placeholder={optionalPlaceHolder} value={fare} name="fare" type="text" onChange={e => setInput(e)} id="fare-box" />
          </label>
          <label htmlFor="tax-box">
            <p><Translate string={"fare.tax"} />:</p>
            <input placeholder={optionalPlaceHolder} value={tax} name="tax" type="text" id="tax-box" onChange={e => setInput(e)}/>
          </label>
          <label htmlFor="fee-box">
            <p><Translate string={"fare.fee"} />:</p>
            <input placeholder={optionalPlaceHolder} value={fee} name="fee" type="text" id="fee-box" onChange={e => setInput(e)}/>
          </label>
          {/* <div className="add-child-infant">
            <p>Add Child</p>
            <p>Add Infant</p>
          </div> */}
          </div>
        </div>
      </div>
    );
  }
}

export default FormEntry;
