import React, { Component } from "react";

import { Redirect } from "react-router-dom";

import {StripeProvider} from 'react-stripe-elements';
import StripeCheckout from '../stripe/StripeCheckout'

class StripePaymentForm extends Component {

  state = {
    braintreeToken: "",
    redirect: false
  };

  render() {

      if(this.state.redirect) {
        return  <Redirect to={{
          pathname: '/members',
      }}/>
    }
    let currentUser = this.props.location.state.user;
    let { plan, selectedPlan } = this.props.location.state;
    return (
      //test key
      // <StripeProvider apiKey="pk_test_z8lxWD8alT1im8jEJMkDfKow004v41xYYA">
      //live key
      <StripeProvider apiKey="pk_live_WckEOZg1SSNSD2OZmKw3CrzF00m5FRyY65">
        <StripeCheckout user_id={currentUser.id} selectedPlan={selectedPlan} plan={plan} updateUser={this.props.updateUser} currentUser={currentUser}/>
      </StripeProvider>
    );
  }
}

export default StripePaymentForm;