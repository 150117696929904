import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Router} from "react-router-dom";
import createHistory from 'history/createBrowserHistory'
import ReactGA from 'react-ga';
import ReactGA4 from "react-ga4";

const history = createHistory()
ReactGA.initialize('UA-107272376-1');
ReactGA4.initialize('G-XBQZFYXFRR');
history.listen((location, action) => {
    ReactGA.pageview(location.pathname + location.search);
    ReactGA4.send({ hitType: "pageview", page: location.pathname + location.search });
});

ReactDOM.render(<Router  history={history}><App /></Router>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
