import React, { Component } from "react";
import Translate from "../translations/Translate";
import { Link } from "react-router-dom";

class Nav extends Component {
  render() {
    return (
      <div className="nav-wrapper">
      <nav role="navigation">
        <div id="menuToggle">
          <input type="checkbox" />

          <span />
          <span />
          <span />

          <ul id="menu">
            <li className="navmenuitem">
              <Link
                to={
                  this.props.value === "en" ? "/" : `/intl/${this.props.value}`
                }
              >
                <i className="material-icons nav-icon">home</i>
                <Translate string={"menu.home"} />
              </Link>
            </li>
            <li className="navmenuitem">
              <Link to="/api-introduction">
                <i className="material-icons nav-icon">api</i>
                <Translate string={"menu.api"} />
              </Link>
            </li>

            <li className="navmenuitem">
              <Link to="/how-to-use-pnrconverter">
                <i className="material-icons nav-icon">flight_takeoff</i>
                <Translate string={"menu.how-it-works"} />
              </Link>
            </li>
            {/* <li className="navmenuitem">
              <Link to="/make-a-suggestion">
                <i className="material-icons nav-icon">comment</i>
                <Translate string={"menu.suggestions"} />
              </Link>
            </li> */}
            <li className="navmenuitem">
              <Link to="/about-us">
                <i className="material-icons nav-icon">group</i>
                <Translate string={"menu.about-us"} />
              </Link>
            </li>

            <li className="navmenuitem">
              <Link to="/blog">
                <i className="material-icons nav-icon">feed</i>

                <Translate string={"menu.blog"} />
              </Link>
            </li>
            <li className="navmenuitem">
              <div className={"lang-menu-item"}>
                <img
                  src={`/images/languages/${this.props.value}.svg`}
                  width="26"
                  className="langImage"
                  id="languageImage"
                  alt="change language"
                />
                <p>{this.props.value}</p>
              </div>
              <ul className="languageDropdown">
                <Link to="/">
                  <li>
                    <img
                      className="flag"
                      src="/images/languages/en.svg"
                      width="26"
                      alt="english language"
                    />
                    <p>English</p>
                  </li>
                </Link>
                <Link to="/intl/es">
                  <li>
                    <img
                      className="flag"
                      src="/images/languages/es.svg"
                      width="26"
                      alt="spanish language"
                    />
                    Español
                  </li>
                </Link>
                <Link to="/intl/cn">
                  <li>
                    <img
                      className="flag"
                      src="/images/languages/cn.svg"
                      width="26"
                      alt="chinese language"
                    />
                    中文
                  </li>
                </Link>
                <Link to="/intl/pt">
                  <li>
                    <img
                      className="flag"
                      src="/images/languages/pt.svg"
                      width="26"
                      alt="portuguese language"
                    />
                    Português
                  </li>
                </Link>
                <Link to="/intl/de">
                  <li>
                    <img
                      className="flag"
                      src="/images/languages/de.svg"
                      width="26"
                      alt="german language"
                    />
                    Deutsche
                  </li>
                </Link>
                <Link to="/intl/fr">
                  <li>
                    <img
                      className="flag"
                      src="/images/languages/fr.svg"
                      width="26"
                      alt="french language"
                    />
                    Français
                  </li>
                </Link>
                <Link to="/intl/nl">
                  <li>
                    <img
                      className="flag"
                      src="/images/languages/nl.svg"
                      width="26"
                      alt="french language"
                    />
                    Nederlands
                  </li>
                </Link>
                <Link to="/intl/no">
                  <li>
                    <img
                      className="flag"
                      src="/images/languages/no.svg"
                      width="26"
                      alt="french language"
                    />
                    Norsk
                  </li>
                </Link>
                <Link to="/intl/tr">
                  <li>
                    <img
                      className="flag"
                      src="/images/languages/tr.svg"
                      width="26"
                      alt="Turkish language"
                    />
                    Türk
                  </li>
                </Link>
              </ul>
            </li>
        <div className="social-bar">
          <a href="https://twitter.com/pnrconverter?lang=en">
            <img
              src="/images/icons/twitter2.png"
              alt="twitter2"
              height="36"
              width="36"
            />
          </a>
          <a href="https://www.linkedin.com/in/william-maddicott/">
            <img
              src="/images/icons/linkdin.png"
              alt="linkdin"
              height="36"
              width="36"
            />
          </a>
        </div>
          </ul>
        </div>

      </nav>
      </div>
    );
  }
}

export default Nav;
