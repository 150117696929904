import React, { Component } from "react";
import * as api from "../../db/api";
import SelectPlanForm from "./SelectPlanForm";
import ChangePlanConfirmation from "./ChangePlanConfirmation";

class SelectPlan extends Component {
  state = {
    apiOption: 1,
    redirect: false,
    plans: [],
    currency: "gbp",
    loading: true,
    message: "",
    showConfirmation: false
  };

  handleChange = event => {
    let { value, name } = event.target;
    if (name === "apiOption") {
      value = +value;
    }
    this.setState({
      [name]: value
    });
  };

  showHideConfirmation = () => {
    let showConfirmation = !this.state.showConfirmation;
    this.setState({
      showConfirmation
    });
  };

  changePaidPlan = () => {
    let { apiOption} = this.state;

    let user = {
      ...this.props.user,
      api: apiOption
    };
    this.setState(
      {
        loading: true
      },
      () => {
        api
          .updateSubscription(apiOption, this.props.user.id)
          .then(res => {
            return api.updateUser(user);
          })
          .then(res => {
            this.props.updateUser(res.data.user);
            if (this.props.toggle) {
              this.props.toggle();
            }
          })
          .catch(err => {
            console.log(err);
            this.setState({
              message:
                "There was a problem changing your plan. Please try again, or contact us for support",
              loading: false
            });
          });
      }
    );
  };

  handleSubmit = event => {
    event.preventDefault();
    let { apiOption, existingApiOption } = this.state;

    let user = {
      ...this.props.user,
      api: apiOption
    };

    //if plan is not free - require checkout
    // if user has selected a free plan -> either don't create a subscription or cancel
    //going from free to free, or paid to free
    if (apiOption === 1) {
      // will need to check, and if applicable cancel subscription here.
      if (existingApiOption !== 1 && existingApiOption !== "not set") {
        this.setState(
          {
            loading: true
          },
          this.updateUserAndCancel(user)
        );
      }
      api.updateUser(user).then(res => {
        // console.log(res.data.user);
        this.props.updateUser(res.data.user);
        if (this.props.toggle) {
          this.props.toggle();
        }
      });
      //if user has selected a paid plan
    } else {
      //paid to paid or free to paid

      if (
        existingApiOption !== 1 &&
        existingApiOption !== apiOption &&
        existingApiOption !== "not set"
      ) {
        this.showHideConfirmation();

        //if user has selected paid plan from a paid plan
 
      } else {
        //if user has selected a paid plan from a free plan

        this.props.redirect(apiOption);
      }
    }
  };

  updateUserAndCancel = user => {
    if (this.props.cancelSubscription) {
      this.props.cancelSubscription(user.id);
    }
    this.props.updateUser(user);
    api.updateUser(user);
  };

  componentDidMount = () => {
    let existingApiOption = this.props.user.api;
    let apiOption;
    if (!this.props.defaultPlan || this.props.defaultPlan === "not set") {
      apiOption = 1;
    } else {
      apiOption = +this.props.defaultPlan;
    }
    api
      .getPlans()
      .then(res => {
        // console.log(res.data.plans);
        this.setState({
          plans: res.data.plans,
          apiOption: +apiOption,
          existingApiOption: +existingApiOption || "not set",
          loading:false,
        });
      })
      .catch(err => {
        console.log(err);
      });
  };

  render() {
    let {
      currency,
      plans,
      apiOption,
      loading,
      existingApiOption,
      showConfirmation
    } = this.state;
    let { toggleBox, message, plan, billDate } = this.props;

    let newPlan = plans.find(plan => {
      return plan.id === apiOption;
    });
    return (
      <div>
        {showConfirmation ? (
          <ChangePlanConfirmation
            showHideConfirmation={this.showHideConfirmation}
            plan={plan}
            billDate={billDate}
            newPlan={newPlan}
            changePaidPlan={this.changePaidPlan}
            loading={loading}
          />
        ) : (
          <SelectPlanForm
            defaultcurrency={this.props.user.defaultcurrency}
            plans={plans}
            currency={currency}
            apiOption={apiOption}
            loading={loading}
            existingApiOption={existingApiOption}
            handleChange={this.handleChange}
            handleSubmit={this.handleSubmit}
            toggleBox={toggleBox}
            message={message}
          />
        )}
      </div>
    );
  }
}

export default SelectPlan;
