import React, { Component } from 'react';

class NothingEntered extends Component {
  render() {
    return (
      <div className="resultsbox">
        <p>You Havn't Entered Anything - Please copy and pasts something from your gds such as <pre>
        QR 328 V 29JAN 2*LGWDOH DK1  1405 2359  29JAN  E  0 788 M                  
     MANDATORY APIS REQUIRED UNDER DOCS DOCO DOCA                               
     SEE RTSVC                                                                  
  4  QR 962 V 30JAN 3*DOHDPS DK1  0235 1730  30JAN

          </pre></p>
      </div>
    );
  }
}

export default NothingEntered;