import React from "react";
import translateFunc from "../translations/TranslateFunction";

const PriceSummary = props => {
  console.log(props);
  let { fare, fee, fareCurrency, tax, value, adtQty } = props;
  console.log(adtQty);
  return (
    <div className="fare-summary fare-adult">
      {fare && (
        <p>{`${translateFunc(value, "fare.fare")}${adtQty>1 ? ` X ${adtQty}`:""}: ${fareCurrency}${Number(
          fare
        ).toFixed(2)}`}<br/></p>
      )}
      {tax && (
        <p>
          {`${translateFunc(value, "fare.tax")}: ${fareCurrency}${Number(
            tax
          ).toFixed(2)}`}
          <br />
        </p>
      )}
      {fee && <p>{`${translateFunc(value, "fare.fee")}: ${fareCurrency}${Number(
          fee
        ).toFixed(2)}`}<br/></p>
        }
      {(fare || tax || fee) && <p className="fare-totals">{`${translateFunc(value, "fare.total")}: ${fareCurrency}${((
          Number(fare) +
          Number(tax) +
          Number(fee)
        )*adtQty).toFixed(2)}`}</p>
        }
    </div>
  );
};

export default PriceSummary;
