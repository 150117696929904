import React, { Component } from "react";
import Loader from "../Loader";

class ChangePlanConfirmation extends Component {
  render() {
    let { plan, billDate, newPlan } = this.props;
    return (
      <div className="change-plan-confirmation">
        <h1>Please review your changes</h1>
        <div className="summary-container">
          <div className="current-plan">
            <h3>Your current plan.</h3>
            <div className="change-plan-box">

           
            <span className="plan-head">{`${plan.name}`}</span>
            <span className="plan-calls">
              {`${plan.monthly_limit} Monthly API calls`
                // .replace(/(?<!\.\d+)\B(?=(\d{3})+\b)/g, " ")
                // .replace(/(?<=\.(\d{3})+)\B/g, " ")
                }
            </span>
            <span className="plan-cost">
              {plan.currency === "na"
                ? `Free`
                : `${plan.currency_symbol}${plan.cost} per month`}
            </span>
            <span>Billing Date:{billDate}</span>
          </div>
          </div>
          <div className="arrow" />
          <div className="new-plan">
            <h3>Your new plan.</h3>
            <div className="change-plan-box">
            <span className="plan-head">{`${newPlan.name}`}</span>
            <span className="plan-calls">
              {`${newPlan.monthly_limit} Monthly API calls`
                // .replace(/(?<!\.\d+)\B(?=(\d{3})+\b)/g, " ")
                // .replace(/(?<=\.(\d{3})+)\B/g, " ")
                }
            </span>
            <span className="plan-cost">
              {newPlan.currency === "na"
                ? `Free`
                : `${newPlan.currency_symbol}${newPlan.cost} per month`}
            </span>
            <span>Billing Date:{billDate}</span>
            <span>
              <h5>Recurring</h5>
              <p>
                You will be billed monthly unless you cancel your subscription
              </p>
            </span>
          </div>
        </div>
          </div>
        <p className="warning change-note"> If you are upgrading mid billing cycle then you will be billed a prorated amount today. If you are down grading to a cheaper plan, then your account will be credited a prorated amount which will come off your next monthly payment</p>
            {this.props.loading?<Loader />:<div className="button-container">
          <button
            type="button"
            className="button secondary"
            onClick={this.props.showHideConfirmation}
          >
            Go Back
            <img src="/images/left-arrow.svg" alt="left arrow" height="16"/>
          </button>
          <button type="button" className="button" onClick={this.props.changePaidPlan}>
            Confirm Change
            <img src="/images/tick.svg" alt="tick" height="16"/>
          </button>
          </div>}
        
      </div>
    );
  }
}

export default ChangePlanConfirmation;
