// CheckoutForm.js
import React from "react";
import {
  injectStripe,
  CardElement,
} from "react-stripe-elements";
import * as api from "../../db/api";
import Loader from "../Loader";
import { Redirect } from "react-router-dom";
import ReactGA from "react-ga";

class CheckoutForm extends React.Component {
  state = {
    name: "",
    amount: "",
    plan: {
      description: "",
      name: "",
      cost: ""
    },
    loading: false,
    errorMessage: "",
    redirect: false
  };

  componentDidMount = () => {

    api
      .getPlanBySlug(this.props.selectedPlan)
      .then(res => {
        this.setState({
          plan: res.data.plan
        });
      ReactGA.event({
      category: "User",
      action: "load checkout",
      label: `user loaded the checkout with plan ${this.state.plan.stripe_plan}`
    });
      })
      .catch(err => {
        console.log(err);
      });
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  handleSubmit = async e => {
    e.preventDefault();
    this.setState(
      {
        loading: true
      },
      this.processPayment
    );
  };

  enableRedirect = () => {
    this.setState({
      redirect: true
    });
  };

  processPayment = async () => {
    let { updateUser } = this.props;
    try {
      let { token } = await this.props.stripe.createToken({
        name: this.state.name
      });
      let amount = this.state.amount;
      api.processPayment(token, amount, this.props.user_id, this.props.selectedPlan)
        .then(res => {
          ReactGA.event({
            category: "User",
            action: "submitted on checkout",
            label: `user id ${this.props.user_id} subscribed to ${this.props.selectedPlan}`
          });
          let {currentUser} = this.props;
          if (res.status === 200) {
            let user = {
              ...currentUser,
              api: res.data.plan
            };
            api.updateUser(user)
            .then(res=>{
              updateUser(res.data.user);
            })
            this.enableRedirect();
          }
        })
        .catch(err => {
            console.log(err);
            ReactGA.event({
              category: "User",
              action: "error on checkout",
              label: `user id ${this.props.user_id} tried to subscribe to plan ${this.props.selectedPlan}`
            });
          if (err.response) {
            console.log(err.response.data);
            console.log(err.response.status);
            console.log(err.response.headers);
            if ((err.response.status = 400)) {
              this.setState({
                loading: false,
                errorMessage: `Your card issuer has not been authorised the transaction. Please double check your details and try again. If the problem persists please contact  your bank`
              });
            }
          }
        });
    } catch (e) {
      throw e;
    }
  };

  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: "/members"
          }}
        />
      );
    }
    return (
      <div className="stripeSignup">
        <img src="./images/checkoutheader.svg" alt="" />
        <header>
          <img src="./images/stripelogo2.png" alt="" />
          <h1>PNR Converter Secure Checkout</h1>
        </header>
        <main>
          <form onSubmit={this.handleSubmit} className="cc-form">
            <h3 className="warning">{this.state.errorMessage}</h3>
            <input
              type="text"
              name="name"
              id="stripe-name"
              value={this.state.name}
              onChange={this.handleChange}
              placeholder="Name"
            />
            <CardElement className="card-element" />
            {this.state.loading ? (
              <Loader />
            ) : (
              <button className="button">Start Subscription</button>
            )}
                      <p className="footnote">
            All payments are provided by our secure payment partner stripe.com. PNR
            Converter does not store your card details on our servers
          </p>
          </form>
          <div className="summary">
            <h2>Summary</h2>
            <div className="summary-content">
              <img src="./images/pnrc-logo2.svg" alt="" />
              <p>{`You are signing up to the following API plan:`}</p>
              <h5>Monthly API Calls:</h5>{" "}
              <p className="">{`${this.state.plan.description}`}</p>
              <h5>Monthly Cost:</h5>{" "}
              <p>{`${this.state.plan.currency_symbol}${
                this.state.plan.cost
              } per month (${this.state.plan.currency &&
                this.state.plan.currency.toUpperCase()})`}</p>
              <h5>First Charge:</h5>
              <p>
                {new Date().toLocaleString("en-us", {
                  day: "numeric",
                  month: "long",
                  year: "numeric"
                })}
              </p>
              <h5>Recurring</h5>
              <p>
                You will be billed monthly unless you cancel your subscription
              </p>
              <h5 className="grid-span blue-text">
                You can cancel your subscription at any time - no contract
              </h5>
              <h5 className="grid-span blue-text">Upgrade at any time</h5>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

export default injectStripe(CheckoutForm);
