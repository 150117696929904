import Translate from "../translations/Translate";
import SignUp from "./auth/SignUpBox";
import React, { Component } from "react";
import * as api from "../db/api";
import { Redirect } from "react-router-dom";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
class Header extends Component {
  state = {
    signup: false,
    members: false,
    allowSignup: true,
    logout: false,
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.members !== prevState.members) {
      this.setState({
        members: false,
      });
    }
    if (this.state.logout !== prevState.logout) {
      this.setState({
        logout: false,
      });
    }
  };

  componentDidMount = () => {
    // document.addEventListener("keypress", this._handleKeyDown);
  };

  // _handleKeyDown = e => {
  //   if (e.key === "D") {
  //     this.setState({
  //       allowSignup: true
  //     });
  //   } else {
  //     this.setState({
  //       allowSignup: false
  //     });
  //   }
  // };

  activateSignUp = () => {
    let signUpToggle = this.state.signup;
    ReactGA.event({
      category: "User",
      action: "Clicked Sign Up Button",
    });
    this.setState({
      signup: !signUpToggle,
    });
  };

  logOut = () => {
    api.logout();
    this.props.logUserOut();
    this.setState({
      logout: true,
    });
  };

  membersArea = () => {
    this.setState({
      members: true,
    });
  };

  render() {
    let { signup, members, logout } = this.state;
    if (members) {
      return (
        <Redirect
          to={{
            pathname: "/members",
          }}
        />
      );
    }
    if (logout) {
      return (
        <Redirect
          to={{
            pathname: "/",
            state: {
              user: this.state.user,
            },
          }}
        />
      );
    }
    return (
      <>
        <Helmet>
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/icon?family=Material+Icons"
          />
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="/apple-touch-icon.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="/favicon-32x32.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="/favicon-16x16.png"
          />
          <link rel="manifest" href="/site.webmanifest" />
          <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
          <meta name="msapplication-TileColor" content="#da532c" />
          <meta name="theme-color" content="#ffffff"></meta>
        </Helmet>
        <header>
          <div className={"header-wrapper"}>
            <a
              href={
                this.props.language === "en"
                  ? "/"
                  : `/intl/${this.props.language}`
              }
            >
              <img
                src="/images/pnrc-logo2.svg"
                id="headerlogo"
                height="53"
                width="120"
                alt="pnrconverter logo"
              />
            </a>
            <div className="title-text-holder">
              <h1>
                <Translate string={"website.title"} />
                {this.props.user &&
                  this.props.user.agencyname &&
                  ` for ${this.props.user.agencyname}`}
              </h1>
              <h3>
                <Translate string={"header.tagline"} />
              </h3>
            </div>

            <a href="/carbon">
              <img
                className="carbon-neutral-image"
                src="/images/icons/carbonneutral.svg"
                alt="carbon neutral"
              />
            </a>
            {this.props.user && this.props.user.name ? (
              <div className="header-button-container">
                <button className="button" onClick={() => this.membersArea()}>
                  <p className="icon-members">{`${
                    this.props.user.api === "not set"
                      ? "My Account"
                      : "My Account"
                  }`}</p>
                </button>
                <button
                  className="button secondary "
                  onClick={() => this.logOut()}
                >
                  <p className="icon-logout-green">Logout</p>
                </button>
              </div>
            ) : (
              <button
                className="button"
                onClick={() => {
                  this.activateSignUp();
                }}
                disabled={!this.state.allowSignup}
              >
                <i className="material-icons nav-icon">api</i>
                <p className="icon-api-red">Access our API</p>
              </button>
            )}

            {signup && (
              <SignUp
                setTokenInStorage={this.props.setTokenInStorage}
                activateSignUp={this.activateSignUp}
                membersArea={this.membersArea}
                setUserInState={this.props.setUserInState}
              />
            )}
          </div>
        </header>
      </>
    );
  }
}

export default Header;
