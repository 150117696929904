import React, { Component } from "react";
import SelectPlan from "./SelectPlan";

import { Redirect } from "react-router-dom";

class MemberChangeSubscription extends Component {
  state = {
    redirect: false,
    selectedPlan:1
  };

  redirect = (selectedPlan) => {
    this.setState({
      redirect: true,
      selectedPlan
    });
  };

  render() {
    let {user, plan, billDate} = this.props;
    let {selectedPlan} = this.state;
    if(this.state.redirect) {
      return  <Redirect to={{
        pathname: '/checkout',
        state: { user, selectedPlan }
    }}/>
  }
    return (
      <div className="modal">
        <div className="memberChangeSubscription modal-content">
          <SelectPlan
            user={this.props.user}
            updateUser={this.props.updateUser}
            message={`Please Choose a PNR Converter API plan`}
            toggle={this.props.toggle}
            defaultPlan={this.props.defaultPlan}
            redirect={this.redirect}
            cancelSubscription={this.props.cancelSubscription}
            toggleBox={this.props.toggle}
            plan={plan}
            billDate={billDate}
            // selectedPlan={this.state.selectedPlan}
          />
        </div>
      </div>
    );
  }
}

export default MemberChangeSubscription;
