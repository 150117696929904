import React, { Component } from "react";
import SelectPlan from "./SelectPlan";
import MemberDashboard from "./MemberDashboard";
import * as api from "../../db/api";
import { Redirect } from "react-router-dom";

class Members extends Component {
  state = {
    redirect: false,
    selectedPlan: {},
    logoutRedirect: false
  };

  redirect = selectedPlan => {
    this.setState({
      redirect: true,
      selectedPlan
    });
  };

  logUserOutMembersArea = () => {
    api.logout();
    this.props.logUserOut();
    this.setState({
      logoutRedirect: true
    });
  };

  render() {
    let { user } = this.props;
    let { selectedPlan } = this.state;
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: "/checkout",
            state: { user, selectedPlan }
          }}
        />
      );
    }

    // if (this.state.logoutRedirect) {
    //   return (
    //     <Redirect
    //       to={{
    //         pathname: "/checkout",
    //         state: { user, plan }
    //       }}
    //     />
    //   );
    // }

    return (
        <div className="blurb members">

          <h1>{`Welcome to the API admin for ${user.agencyname}`}</h1>
          {user.api === "not set" ? (
            <SelectPlan
              user={user}
              updateUser={this.props.updateUser}
              redirect={this.redirect}
              message={`Looks Like it's your first time here! Please choose a plan!`}
              
            />
          ) : (
            <MemberDashboard
              user={user}
              updateUser={this.props.updateUser}
              logUserOutMembersArea={this.logUserOutMembersArea}
            />
          )}
        </div>
    );
  }
}

export default Members;
