import React from "react";

import { Link } from "react-router-dom";

const apiterms = () => {
  return (
    <div className="blurb">
      <h2>Acceptance of Terms</h2>
      <p>
        This PNR Converter API Terms of Service document (the “API TOS”) is an
        agreement you must accept in order to use the PNR Converter API (as
        described below). This document describes both your rights and your
        obligations as part of using the PNR Converter API. It is important that
        you read it carefully because you will be legally bound to these terms.
        PNR Converter. (“PNR Converter” “we” “us”) only provides the PNR
        Converter API to you subject to this API TOS. By accepting this API TOS
        or by accessing or using the PNR Converter API, you agree to be bound by
        this API TOS (including the Privacy Policy, and general Terms of Service
        for Users and Customers, which are incorporated here by reference).
      </p>
      <p>
        If you are entering into this API TOS on behalf of a company or other
        legal entity, you represent that you have the authority to bind such
        entity to this API TOS. In that case, the terms “you” or “your” shall
        also refer to such entity. If you do not have such authority, or if you
        do not agree with this API TOS, you may not use the PNR Converter API.
        You acknowledge that this API TOS is a contract between you and PNR
        Converter, even though it is electronic and is not physically signed by
        you and PNR Converter, and it governs your use of the PNR Converter API.
      </p>
      <h2>API Key Definitions</h2>
      <p>
        Application – Any software application, website, or product you create
        or service you offer using the PNR Converter API (defined below). API
        Documentation – The documentation, data and information that PNR
        Converter provides regarding the use of the PNR Converter API through
        the Developer Site.
      </p>
      <p>
        Data – means any data and content uploaded, posted, transmitted or
        otherwise made available by users via the Services, including messages,
        files, comments, profile information and anything else entered or
        uploaded into the Service by a user of the Service.
      </p>
      <p>
        PNR Converter API or “our API” – The publicly available PNR Converter
        Application Programming Interface (“API”) as well as the related API
        Documentation.
      </p>
      <p>
        PNR Converter Brand – The PNR Converter brand and brand assets,
        including names, logos, trade names and trademarks.
      </p>
      <p>
        PNR Converter Service(s) – PNR Converter’s business process management
        and related systems and technologies, as well as the website
        https://www.pnrconverter.com (the “Site”), and all software,
        applications, data, reports, text, images, and other content made
        available by or on behalf of PNR Converter through any of the foregoing.
        The “Service” does not include Data or any software application or
        service that is provided by you or a third party (including
        Applications), whether or not PNR Converter designates them as “official
        integrations”. API License
      </p>
      <p>
        As long as you follow this API TOS, PNR Converter grants you a limited,
        non-exclusive, non-assignable, non-transferable license to use the APIs
        to develop, test, and support any software application, website, or
        product, and to integrate the PNR Converter APIs with your Application.
        Your license is subject to the limitations set forth in Section 4.2
        through 4.11 and Section 9 below, and you agree that violation of
        Section 4 will automatically terminate your license to use the PNR
        Converter APIs.
      </p>
      <h2>Use of APIs and PNR Converter Data</h2>
      <p>
        The following identifies limitations on use related to the PNR Converter
        API:
      </p>
      <ul>
        <li>
          <h3>Reliance on API</h3>
          <p>
            PNR Converter is still evolving, and we need the flexibility to
            occasionally make changes to our APIs, including backwards
            incompatible ones. We will try to give notice of such changes. Also,
            parts of our API are undocumented, including certain methods,
            events, and properties. Given that these undocumented aspects of our
            API may change at any time, you should not rely on these behaviors.
          </p>
          <p>
            PNR Converter is deployed on the latest cloud servers to minimise
            down time. While we endever to keep downtime to an absolute minimum,
            we only promise to deliver 99.9% up time. Furthermore PNR Converter
            will be in no way liable for any loss of business or income, either
            directly or indirectly as a result of the API being unavailable.
          </p>
          <p>
            PNR Converter is updated on a regular basis to ensure that the data
            it provides is both accurate and up to date. However, PNR Converter
            makes no guarantees on the accuracy of the data. It is your
            reponsibility to verify the data before it is delivered to clients.
            PNR Converter will be in no way liable for any costs occured by you,
            either directly or indirectly as a result of errors in the PNR
            Converter API. Should you notice any inaccurate information then you
            should inform us and we will update our records within 48 hours.
          </p>
        </li>

        <li>
          <h3>Applicable laws or agreements.</h3>
          <p>
            You may not use the PNR Converter API in violation of any law or
            regulation, or rights of any person, including but not limited to
            intellectual property rights, rights of privacy, or rights of
            personality, or in any manner inconsistent with this API TOS or PNR
            Converter’s other agreements to which you are subject. Scope of
            acceptable use. You may not use the PNR Converter API or any other
            technology in a manner that accesses or uses any information beyond
            what PNR Converter allows under this API TOS or the Documentation;
            that changes the PNR Converter Service; that breaks or circumvents
            any of PNR Converter’s technical, administrative, process or
            security measures; that disrupts or degrades the performance of the
            PNR Converter Service or the PNR Converter API; or that tests the
            vulnerability of PNR Converter’s systems or networks.{" "}
          </p>
        </li>

        <li>
          <h3>Malware.</h3>
          <p>
            You may not transmit any viruses or other computer programming that
            may damage, detrimentally interfere with, surreptitiously intercept,
            or expropriate any system or data.
          </p>
        </li>
        <li>
          <h3>Reverse engineering</h3>
          <p>
            You may not attempt to reverse engineer or otherwise derive source
            code, trade secrets, or know-how in the PNR Converter API or portion
            thereof;
          </p>
        </li>

        <li>
          <h3>Functionality</h3>
          <p>
            You may not use the PNR Converter API to replicate or compete with
            core products or services offered by PNR Converter. You acknowledge
            and agree that PNR Converter has or may in the future offer products
            or services that are similar to your Application, and nothing will
            prevent PNR Converter from doing so;
          </p>
        </li>

        <li>
          <h3>Commercial Use</h3>
          <p>
            You may charge for your Application. However, you may not sell,
            rent, lease, sublicense, redistribute, or syndicate access to the
            PNR Converter API.
          </p>
        </li>

        <li>
          <h3>Advertising</h3>
          <p>You may place advertisements on and around your Application.</p>
        </li>
        <li>
          <h3>Caching Data</h3>
          <p>
            Where Data is cached, you should refresh the cache at least every 24
            hours.
          </p>
        </li>
        <li>
          <h3>Secure Storage Measures.</h3>
          <p>All Data should be stored and served using strong encryption.</p>
        </li>
        <li>
          <h3>Deletion at Termination</h3>
          <p>
            If we terminate your use of the PNR Converter APIs for any reason,
            then you must permanently delete all Data and any other information
            that you stored pursuant to your use of the PNR Converter APIs,
            except when doing so would cause you to violate any law or
            obligation imposed by a governmental authority. This provision does
            not require a PNR Converter user to delete Data stored in PNR
            Converter if the PNR Converter user is prohibited from accessing the
            PNR Converter API. No Other Storing. You may not copy or store any
            Data or capture or store any information expressed by the Data (such
            as hashed or transferred data), except to the extent permitted by
            this API TOS. Your User Agreement and Privacy Policy
          </p>
        </li>
        <li>
          <h3>Security Measures</h3>
          <p>
            Protections. The network, operating system and software of your web
            servers, databases, and computer systems (collectively, “Your
            Systems”) must be properly configured to securely operate your
            Application and store Data. Your Application must use reasonable
            security measures to protect your users’ information. You must not
            architect or select Your Systems in a manner to avoid the foregoing
            obligation.
          </p>
        </li>
        <li>
          <h3>Protections</h3>
          <p>
            Protections. The network, operating system and software of your web
            servers, databases, and computer systems (collectively, “Your
            Systems”) must be properly configured to securely operate your
            Application and store Data. Your Application must use reasonable
            security measures to protect your users’ information. You must not
            architect or select Your Systems in a manner to avoid the foregoing
            obligation.
          </p>
        </li>
        <li>
          <h3>Reporting</h3>
          <p>
            You must promptly report any security deficiencies in, or intrusions
            to, your Systems to PNR Converter in writing via email to
            api@pnrconverter.com or subsequent contact information posted on the
            Developer Site. This includes any unauthorized access, use,
            disclosure or destruction of Data. You will work with PNR Converter
            to immediately correct any security deficiency, and will immediately
            disconnect any intrusions or intruder. In the event of any security
            deficiency or intrusion involving the Application, PNR Converter
            APIs or Data, you will make no public statements regarding such
            deficiencies or intrusions (e.g., press, blogs, social media,
            bulletin boards, etc.) without prior written and express permission
            from PNR Converter in each instance.
          </p>
        </li>
        <li>
          <h3>Government Access. You will not knowingly</h3>
          <p>
            Allow or assist any government entities, law enforcement, or other
            organizations to conduct surveillance or obtain data using your
            access to the PNR Converter API in order to avoid serving legal
            process directly on PNR Converter. Any such use by you for law
            enforcement purposes is a breach of this API TOS. Display,
            distribute or otherwise make available Data or any Application to
            any person or entity that you reasonably believe will use Data to
            violate the Universal Declaration of Human Rights (located at
            http://www.un.org/en/documents/udhr/), including without limitation
            Articles 12, 18, or 19. Any law enforcement personnel seeking
            information about PNR Converter users will be referred to PNR
            Converter’s User Data Request Policy. You will not conduct and your
            Application will not provide analyses or research that isolates a
            small group of individuals or any single individual for any unlawful
            or discriminatory purposes. Exemptions to these restrictions may be
            requested for exigent circumstances and are subject to prior written
            approval from PNR Converter.
          </p>
        </li>
        <li>
          <h3>Government Use.</h3>
          <p>
            The PNR Converter APIs are “commercial items” as that term is
            defined at 48 C.F.R. 2.101, consisting of “commercial computer
            software” and “commercial computer software documentation” as such
            terms are used in 48 C.F.R. 12.212. Any use, modification,
            derivative, reproduction, release, performance, display, disclosure
            or distribution of the PNR Converter API by any government entity is
            prohibited, except as expressly permitted by the terms of this API
            TOS. Additionally, any use by U.S. government entities must be in
            accordance with 48 C.F.R. 12.212 and 48 C.F.R. 227.7202-1 through
            227.7202-4. The contractor/manufacturer is PNR Converter, Inc., 911
            Washington Avenue, Suite 501, St. Louis, MO 63101 United States.
          </p>
        </li>
        <li>
          <h3>Ownership.</h3>
          <p>
            As between you and us, we own all rights, title, and interest,
            including all intellectual property rights, in and to, the (1) PNR
            Converter APIs, Documentation, and all elements and components
            thereof; (2) Data; (3) PNR Converter Services; and (4) Brand
            Features (collectively, the “PNR Converter Materials”). The only
            exception to this is Data that you as a PNR Converter user have
            licensed to PNR Converter under the Terms of Service, which governs
            that Data. Except for the express licenses granted in this API TOS,
            PNR Converter does not grant you any right, title, or interest in
            the PNR Converter Materials. You agree to take such actions as PNR
            Converter may reasonably request to perfect PNR Converter’s rights
            to the PNR Converter Materials.
          </p>
        </li>
        <h2>Term and Termination</h2>
        <li>
          <h3>Duration of Terms.</h3>
          <p>
            This API TOS will go into effect on the date upon which you agree to
            them, by accessing or using the PNR Converter API, and will continue
            until terminated as set forth herein.
          </p>
        </li>
        <li>
          <h3>Your Right to Terminate</h3>
          <p>
            You may terminate this API TOS by discontinuing use of our APIs and
            the PNR Converter Service.
          </p>
        </li>
        <li>
          <h3>Suspension & Termination</h3>
          <p>
            We may change, suspend or discontinue the PNR Converter API and
            suspend or terminate your use of the PNR Converter API, the PNR
            Converter Service, and/or PNR Converter Brand at any time and for
            any reason, without notice. Without limiting the foregoing, we may
            limit your Application’s access to the PNR Converter API if it, in
            our sole discretion, may negatively affect our Service or our
            ability to provide our Service.
          </p>
        </li>
        <li>
          <h3>Effect of Termination</h3>
          <p>Upon termination of this API TOS:</p>
          <p>
            All rights and licenses granted to you will terminate immediately;
            You will promptly destroy Documentation and any other PNR Converter
            information in your possession or control that was received under
            this API TOS; Unless we agree otherwise in writing or as stated in
            this API TOS, you must permanently delete all Data and other
            information that you stored pursuant to your use of the PNR
            Converter APIs. PNR Converter may request that you certify in
            writing your compliance with this section; and PNR Converter will
            make commercially reasonable efforts to remove all references and
            links to your Application from its Services (PNR Converter has no
            other obligation to delete copies of, references to, or links to
            your Application). The following sections of this API TOS shall
            survive any termination, Sections 4 (“Use of the APIs and PNR
            Converter Data”), 5 (“Use of PNR Converter Brand or Marks”), 6.4
            (“Delete at User Request”), 6.5 (“Deletion at Termination”), 7
            (“Your User Agreement and Privacy Policy”), 8 (“Security Measures”),
            11 (“Ownership”), 12 (“Term and Termination”), 13 (“Other Important
            Terms”), 14 (“Disclaimer of Warranties; Limitation of Liability;
            Indemnity”), 15 (“Dispute Resolution”) and 16 (“General Legal
            Terms”). Other Important Terms.
          </p>
        </li>
        <li>
          <h3>Legal Representations</h3>
          <p>
            You represent and warrant to PNR Converter that, excluding PNR
            Converter Materials, you have the right to use, reproduce, transmit,
            copy, publicly display, publicly perform, and distribute your
            Application, and that use of your Application by PNR Converter and
            its users will not violate the rights of any third party (e.g.,
            copyright, patent, trademark, privacy, publicity or other
            proprietary right of any person or entity), or any applicable
            regulation or law, including the Digital Millennium Copyright Act,
            the laws of any country in which your Application is made available
            and any applicable export laws. Modification of the API TOS. We may
            change, add to or delete this API TOS or any portion thereof from
            time to time in our sole discretion. If we make a material change to
            this API TOs, we will provide you with reasonable notice prior to
            the changes either by emailing the email address associated with
            your account or by posting a notice on the Developer Site. You
            acknowledge that these updates and modifications may adversely
            impact how you access, use, and communicate with the PNR Converter
            API. If any change in unacceptable to you, then your only recourse
            is to cease all use of the PNR Converter API. Your continued access
            or use of the PNR Converter API will mean that you agree to the
            updates and modifications.
          </p>
        </li>
        <li>
          <h3>Other Applicable Agreements</h3>
          <p>
            You and your Application must also comply with the following, which
            are hereby incorporated by reference:{" "}
            <Link to={`/api-terms-conditions`}>
              PNR Converter Terms of Service for Users and Customers.
            </Link>{" "}
            <Link to={`/privacy`}>PNR Converter Privacy Policy.</Link> In the
            event of any conflict between the content in this document and the
            above documents, this document controls your use of the PNR
            Converter APIs.
          </p>
        </li>
        <h2>Disclaimer of Warranties; Limitation of Liability; Indemnity</h2>
        <li>
          <h3>NO WARRANTIES</h3>
          <p>
            THE PNR CONVERTER API AND ALL RELATED COMPONENTS AND INFORMATION ARE
            PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS WITHOUT ANY
            WARRANTIES OF ANY KIND, AND PNR CONVERTER EXPRESSLY DISCLAIMS ANY
            AND ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT
            LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, TITLE,
            FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. YOU
            ACKNOWLEDGE THAT PNR CONVERTER DOES NOT WARRANT THAT THE PNR
            CONVERTER API WILL BE UNINTERRUPTED, TIMELY, SECURE, ERROR-FREE OR
            VIRUS-FREE, NOR DOES IT MAKE ANY WARRANTY AS TO THE RESULTS THAT MAY
            BE OBTAINED FROM USE OF THE PNR CONVERTER API, AND NO INFORMATION,
            ADVICE OR SERVICES OBTAINED BY YOU FROM PNR CONVERTER OR THROUGH THE
            DEVELOPER SITE SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN
            THIS API TOS.
          </p>
        </li>
        <li>
          <h3>LIMITATION ON LIABILITY</h3>
          <p>
            UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL THEORY (WHETHER IN
            CONTRACT, TORT, OR OTHERWISE) SHALL PNR CONVERTER BE LIABLE TO YOU
            OR ANY THIRD PARTY FOR (A) ANY INDIRECT, INCIDENTAL, SPECIAL,
            EXEMPLARY, CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING LOST
            PROFITS, LOST SALES OR BUSINESS, LOST DATA, OR (B) FOR ANY AMOUNT IN
            THE AGGREGATE IN EXCESS OF THE FEES ACTUALLY PAID BY YOU IN THE SIX
            (6) MONTHS PRECEDING THE EVENT GIVING RISE TO YOUR CLAIM OR, IF NO
            FEES APPLY, ONE HUNDRED ($100) U.S. DOLLARS, OR (C) ANY MATTER
            BEYOND OUR REASONABLE CONTROL. THE PROVISIONS OF THIS SECTION
            ALLOCATE THE RISKS UNDER THIS TOS BETWEEN THE PARTIES, AND THE
            PARTIES HAVE RELIED ON THESE LIMITATIONS IN DETERMINING WHETHER TO
            ENTER INTO THIS TOS. Some jurisdictions do not allow the exclusion
            of implied warranties or limitation of liability for incidental or
            consequential damages, which means that some of the above
            limitations may not apply to you. IN THESE JURISDICTIONS, PNR
            CONVERTER’S LIABILITY WILL BE LIMITED TO THE GREATEST EXTENT
            PERMITTED BY LAW.
          </p>
        </li>
        <li>
          <h3>Indemnity</h3>
          <p>
            You agree to defend, hold harmless and indemnify PNR Converter, and
            its subsidiaries, affiliates, officers, agents, employees, and
            suppliers, from and against any third party claim arising from or in
            any way related to your or your users’ use of the Application, PNR
            Converter API or Data, use of PNR Converter Brand, or violation of
            this API TOS, including any liability or expense arising from all
            claims, losses, damages (actual and consequential), suits,
            judgments, litigation costs and attorneys’ fees, of every kind and
            nature. In such a case, we will provide you with written notice of
            such claim, suit, or action.
          </p>
        </li>
        <li>
          <h3>Dispute Resolution</h3>
          <p>
            This API TOS and any claim, cause of action or dispute (“Claim”)
            arising out of or related to this Agreement shall be governed by the
            British laws, regardless of your country of origin or where you
            access the PNR Converter Service, and notwithstanding any conflicts
            of law principles and the United Nations Convention for the
            International Sale of Goods. You and PNR Converter agree that all
            Claims arising out of or related to this Agreement must be resolved
            exclusively in the courts located in the UK.
          </p>
        </li>
        <h2>General Legal Terms</h2>
        <li>
          <h3>Severability.</h3>
          <p>
            If any provision of this API TOS is found to be illegal, void, or
            unenforceable, the unenforceable provision will be modified so as to
            render it enforceable to the maximum extent possible in order to
            effect the intention of the provision; if a term cannot be so
            modified, it will be severed and the remaining provisions of this
            API TOS will not be affected in any way.
          </p>
        </li>
        <li>
          <h3>Language</h3>
          <p>
            Where PNR Converter has provided you with a translation of the
            English language version of this API TOS or any document referenced
            in this API TOS, you agree that the translation is provided for your
            convenience only and that the English language versions of any such
            document, will control.
          </p>
        </li>
        <li>
          <h3>Notice and Service of Process</h3>
          <p>
            We may notify you via postings on the Developer Site or via the
            email address associated with your Application or PNR Converter
            Service account. PNR Converter accepts service of process by mail or
            courier at the physical address set forth in Section 16.9 below. Any
            notices that you provide without compliance with this section shall
            have no legal effect.
          </p>
        </li>
        <li>
          <h3>Entire Agreement</h3>
          <p>
            This API TOS and any documents incorporated into this API TOS by
            reference, constitute the entire agreement between you and PNR
            Converter regarding the PNR Converter APIs and supersedes all prior
            agreements and understandings, whether written or oral, or whether
            established by custom, practice, policy or precedent, with respect
            to the subject matter of this API TOS.
          </p>
        </li>
        <li>
          <h3>No Informal Waivers, Agreements, or Representations</h3>
          <p>
            Our failure to act with respect to a breach of this API TOS by you
            or others does not waive our right to act with respect to that
            breach or subsequent similar or other breaches. No representations,
            statements, consents, waivers or other acts or omissions by any PNR
            Converter affiliate shall be deemed legally binding on any PNR
            Converter affiliate, unless documented in a physical writing hand
            signed by a duly appointed officer of PNR Converter.
          </p>
        </li>
        <li>
          <h3>Injunctive Relief</h3>
          <p>
            In no event shall you seek or be entitled to rescission, injunctive
            or other equitable relief, or to enjoin or restrain the operation of
            the Developer Site or PNR Converter Service or any PNR Converter
            APIs, or other material used or displayed through the PNR Converter
            Developer Site or PNR Converter Service.
          </p>
        </li>
        <li>
          <h3>Assignment and Delegation</h3>
          <p>
            You may not assign or delegate any rights or obligations under this
            API TOS, including in connection with a change of control. Any
            purported assignment and delegation shall be ineffective. We may
            freely assign or delegate all rights and obligations under this API
            TOs, fully or partially without notice to you. We may also
            substitute, by way of unilateral novation, effective upon notice to
            you, PNR Converter, Inc. for any third party that assumes our rights
            and obligations under this API TOs.
          </p>
        </li>
        <li>
          <h3>Potential Other Rights and Obligations</h3>
          <p>
            You may have rights or obligations under local law other than those
            enumerated here if you are located outside the United States.
          </p>
        </li>
        <h3>How to Contact Us</h3>
        <p>
          If you have questions or comments about this API TOS, or wish to make
          use of our PNR Converter API in any way not permitted by this API TOS,
          please contact us via email on api@pnrconverter.com
        </p>
      </ul>

    </div>
  );
};

export default apiterms;
