import ResultsThreeLines from "./ResultsThreeLines";
import ResultsThreeLinesReordered from "./ResultsThreeLinesReordered";
import ResultsTwoLinesReordered from "./ResultsTwoLinesReordered";
import ResultsTwoLines from "./ResultsTwoLines";
import ResultsTable from "./ResultsTable";
import TransitTime from "./TransitTime";
import translateFunc from "../translations/TranslateFunction";

import React, { Component } from "react";
import Names from "./Names";

import PDFButton from "./PDFButton";
import PriceSummary from "./PriceSummary";

class ResultsBox extends Component {
  state = {};

  selectText = containerid => {
    let range;
    if (document.selection) {
      // IE
      range = document.body.createTextRange();
      range.moveToElementText(document.getElementById(containerid));
      range.select();
    } else if (window.getSelection) {
      range = document.createRange();
      range.selectNode(document.getElementById(containerid));
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
    }
  };

  render() {
    const { options, format, value, names, laravelResults, fare, fee, tax, fareCurrency, adtQty } = this.props;
    const {
      airlineName,
      distanceradio,
      cabinradio,
      duration,
      twelveClock,
      logo,
      transit,
      operatedBy,
      aircraftType,
      tableNotes,
      timezone
    } = options;
    console.log(adtQty);

    return (
      <div>
        <div
          className="selectbutton resultsbox"
          onDoubleClick={() => this.selectText("selectable")}
        >
          {" "}
          {translateFunc(value, "message.doubleclick")}
        </div>

        {/* <PDFButton/> */}

        <div className="resultsbox">
          <div id="selectable">
            {names[0] && <Names names={names} value={value} />}

            {laravelResults.map((result, index) => {
              const { flt, dep, arr } = result;
              // const depDate = new Date(result.flt.departure.string);
              // const arrDate = new Date(result.flt.arrival.string);
              const depDate = new Date(result.flt.departure.UTC);
              const arrDate = new Date(result.flt.arrival.UTC);

              let transitTime;

              if (
                result.flt.transit_time.minutes ||
                result.flt.transit_time.hours
              ) {
                transitTime = result.flt.transit_time;
              } else {
                transitTime = null;
              }

              const languageCode = translateFunc(
                value || "en",
                "language.code"
              );

              const dateFormat = translateFunc(value || "en", "date.format");

              const depDateFormatted = depDate.toLocaleString(languageCode, {
                weekday: dateFormat,
                day: "numeric",
                month: "short",
                timeZone: result.flt.departure.tz
              });
              const depTimeFormatted = `${depDate.toLocaleString(languageCode, {
                hour: "numeric",
                minute: "2-digit",
                hour12: twelveClock,
                timeZone: result.flt.departure.tz
              })}${timezone ? ` (${dep.timezoneshort})` : ""}`;

              // const depTimeFormatted = `${twelveClock?result.flt.departure.hr12:result.flt.departure.hr24}${timezone ? ` (${dep.timezoneshort})` : ""}`;

              const arrTimeFormatted = `${arrDate.toLocaleString(languageCode, {
                hour: "numeric",
                minute: "2-digit",
                hour12: twelveClock,
                timeZone: result.flt.arrival.tz
              })}${timezone ? ` (${arr.timezoneshort})` : ""}`;

              // const arrTimeFormatted = `${twelveClock?result.flt.arrival.hr12:result.flt.arrival.hr24}${timezone ? ` (${arr.timezoneshort})` : ""}`;

              const arrDateFormatted = arrDate.toLocaleString(languageCode, {
                day: "numeric",
                month: "short",
                timeZone: result.flt.arrival.tz
              });

              let nextDay = "";

              if (
                arrDateFormatted !==
                depDate.toLocaleString(languageCode, {
                  day: "numeric",
                  month: "short",
                  timeZone: result.flt.departure.tz
                })
              ) {
                nextDay =
                  " (" +
                  translateFunc(value, "flight.on-the") +
                  " " +
                  arrDateFormatted +
                  ")";
              }

              let depairportString;
              if (dep.airportname.split(" ")[0] === dep.cityname) {
                depairportString =
                  dep.airportname + " (" + dep.airportcode + ")";
              } else {
                depairportString =
                  dep.airportname +
                  ", " +
                  dep.cityname +
                  " (" +
                  dep.airportcode +
                  ")";
              }

              let arrairportString;
              if (arr.airportname.split(" ")[0] === arr.cityname) {
                arrairportString =
                  arr.airportname + " (" + arr.airportcode + ")";
              } else {
                arrairportString =
                  arr.airportname +
                  ", " +
                  arr.cityname +
                  " (" +
                  arr.airportcode +
                  ")";
              }

              return (
                <div
                  className="row"
                  key={`${arrairportString}${arrTimeFormatted}`}
                >
                  <div className="main-content">
                    {logo && (
                      <div className="image-container">
                        <img
                          src={`/images/airlines/png/150/${flt.iatacode.toLowerCase()}.png`}
                          alt="airline logo"
                          width="75"
                        />
                      </div>
                    )}

                    <div className="result-text">
                      <p className="lineOne">
                        {`${depDateFormatted} - ${
                          !airlineName ? flt.iatacode : flt.name
                        } ${flt.flightNo} `}
                        <span className="operatedBy">
                          {`${
                            operatedBy &&
                            flt.operated_by.toLowerCase() !==
                              flt.name.toLowerCase()
                              ? `- Operated By ${flt.operated_by.toLowerCase()}`
                              : ""
                          } `}
                        </span>

                        {`${
                          cabinradio === "cabin"
                            ? "- " + flt.cabin
                            : cabinradio === "className"
                            ? flt.class
                            : ""
                        }${
                          duration
                            ? " - " +
                              flt.duration.hours +
                              translateFunc(value, "time.hours") +
                              " " +
                              flt.duration.minutes +
                              translateFunc(value, "time.minutes") +
                              " "
                            : ""
                        }${
                          distanceradio === "miles"
                            ? "- " +
                              flt.distance.miles +
                              " " +
                              translateFunc(value, "distance.miles")
                            : distanceradio === "km"
                            ? "- " +
                              flt.distance.km +
                              " " +
                              translateFunc(value, "distance.km")
                            : ""
                        }${
                          aircraftType && flt.aircraft
                            ? "- " + flt.aircraft
                            : ""
                        }`}
                      </p>
                      {format === "threelines" && (
                        <ResultsThreeLines
                          depairportString={depairportString}
                          depTimeFormatted={depTimeFormatted}
                          arrairportString={arrairportString}
                          arrTimeFormatted={arrTimeFormatted}
                          nextDay={nextDay}
                          value={value}
                        />
                      )}
                      {format === "threelinesreordered" && (
                        <ResultsThreeLinesReordered
                          depairportString={depairportString}
                          depTimeFormatted={depTimeFormatted}
                          arrairportString={arrairportString}
                          arrTimeFormatted={arrTimeFormatted}
                          nextDay={nextDay}
                          value={value}
                        />
                      )}
                      {format === "twolines" && (
                        <ResultsTwoLines
                          depairportString={depairportString}
                          depTimeFormatted={depTimeFormatted}
                          arrairportString={arrairportString}
                          arrTimeFormatted={arrTimeFormatted}
                          nextDay={nextDay}
                          value={value}
                        />
                      )}
                      {format === "twolinesreordered" && (
                        <ResultsTwoLinesReordered
                          depairportString={depairportString}
                          depTimeFormatted={depTimeFormatted}
                          arrairportString={arrairportString}
                          arrTimeFormatted={arrTimeFormatted}
                          nextDay={nextDay}
                          value={value}
                        />
                      )}
                      {format === "tableoutput" && (
                        <ResultsTable
                          depairportString={depairportString}
                          depTimeFormatted={depTimeFormatted}
                          arrairportString={arrairportString}
                          arrTimeFormatted={arrTimeFormatted}
                          nextDay={nextDay}
                          transitTime={transitTime}
                          options={options}
                          value={value}
                        />
                      )}
                      <TransitTime
                        tt={transitTime}
                        index={index}
                        results={laravelResults}
                        value={value}
                        arrDate={arrDate}
                        transit={transit}
                        logo={logo}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
            <PriceSummary
              fare={fare}
              fee={fee}
              tax={tax}
              fareCurrency={fareCurrency}
              value={value}
              adtQty={adtQty}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ResultsBox;
