import React, { Component } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import $ from 'jquery';

class PDFButton extends Component {
  getPdf = id => {
    const input = document.getElementById(id);

    html2canvas(input, {
      width: 1200,
      height: 1800
    }).then(function(canvas) {
      var a = document.createElement("a");
      a.href = canvas.toDataURL("image/png");
      a.download = "myfile.png";
      a.click();

      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      const imgProps= pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
    //   doc.addImage(imgData, 'JPEG', 0, 0, width, height);
      pdf.save("download.pdf");
    });
  };

  render() {
    return (
      <div
        className="selectbutton resultsbox"
        onClick={() => this.getPdf("selectable")}
      >
        Download PDF
      </div>
    );
  }
}

export default PDFButton;
