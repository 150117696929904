import React, { Component } from "react";

import FormEntry from "./FormEntry";
import FormOptions from "./FormOptions";
import AdvertisingBox from "./AdvertisingBox";
import Blurb from "./Blurb";
import * as func from "../controllers/convert";
import ResultsBoxThreeLines from "./ResultsBox";
import Loader from "./Loader";
import ResultsTable from "./ResultsTable";
import Gdpr from "./Gdpr";
import { LanguageContext } from "../context/language-context";
import translateFunc from "../translations/TranslateFunction";
import InvalidInput from "./InvalidInput";
import ReactGA from "react-ga";
import Adblock from "./Adblock";
import Banner from "./Banner";
import { Helmet } from "react-helmet";
import ReactGA4 from "react-ga4";


class MainScreen extends Component {
  state = {
    user: {},
    language: "en",
    format: "threelines",
    options: {
      airlineName: true,
      logo: true,
      cabinradio: "off",
      transit: true,
      twelveClock: true,
      operatedBy: false,
      distanceradio: "off",
      duration: true,
      systemFonts: false,
      aircraftType: false,
      tableNotes: false,
      timezone: false,
    },
    input: "",
    names: "",
    loading: false,
    showSignUp: false,
    error: false,
    addblocker: false,
    laravelResponse:[],
    adtQty:1,
    fare:"",
    tax:"",
    fee:"",
    fareCurrency:""
  };

  componentDidMount = () => {
    const title = translateFunc(
      this.props.match.params.lang || "en",
      "page.title"
    );
    document.title = title;

    let visited = localStorage["alreadyVisited"];
    let fareCurrency;
    if (localStorage["fare-currency"]) {
      fareCurrency = localStorage["fare-currency"];
    } else {
      fareCurrency = "$";
    }
    if (visited) {
      let options;
      let format;
      if (localStorage["options"]) {
        options = JSON.parse(localStorage["options"]);
      } else {
        options = this.state.options;
      }
      if (localStorage["format"]) {
        format = localStorage["format"];
      } else {
        format = this.state.format;
      }

      this.setState({
        showSignUp: false,
        options,
        format,
        fareCurrency
      });
    } else {
      localStorage["alreadyVisited"] = true;
      let savedOptions = this.state.options;
      localStorage["options"] = JSON.stringify(savedOptions);
      localStorage["format"] = "threelines";
      this.setState({
        showSignUp: true,
        fareCurrency,
      });
    }
    if (
      this.props.match.params.lang &&
      this.props.match.params.lang !== this.props.language
    ) {
      this.props.changeLanguage(this.props.match.params.lang);
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    let {lang} = this.props.match.params;
    let savedOptions = this.state.options;
    localStorage["options"] = JSON.stringify(savedOptions);
    localStorage["format"] = this.state.format;
    localStorage["fare-currency"] = this.state.fareCurrency;

    if (
      lang &&
      lang !== this.props.language
    ) {
      this.props.changeLanguage(lang);
    } else if (!lang && this.props.language !== "en") {
      this.props.changeLanguage("en");
    }

  };

  changeOptions = event => {
    const { options } = this.state;
    const { name, type } = event.target;
    let newOptions;
    if (type === "radio") {
      newOptions = {
        ...options,
        [name]: event.target.value
      };
    } else {
      newOptions = {
        ...options,
        [name]: !options[name]
      };
    }
    this.setState({
      options: newOptions
    });
  };

  pasteIntoBox = () => {
    this.setState({
      adtQty:1,
      fare:"",
      tax:"",
      fee:"",
    })
  }

  GDPRaccept = () => {
    this.setState({
      showSignUp: false
    });
  };

  setFormat = event => {
    const { value } = event.target;
    this.setState({
      format: value
    });
  };

  setInput = event => {
    const { value, name } = event.target;
    this.setState({
      [name]: value
    });
  };

  handleSubmit = event => {
    event.preventDefault();
    const { input, options, format } = this.state;

    ReactGA4.send({ hitType: "convert_itinerary", page: format, language:this.props.language });

    ReactGA.event({
      category: "Convert an Itinerary - Language",
      action: "Converted Itinerary",
      label: this.props.language
    });
    ReactGA.event({
      category: "Convert an Itinerary - format",
      action: "Converted Itinerary",
      label: format
    });

    this.setState(
      {
        loading: true
      },
      () => {
        let names = func.getNames(input);
        let laravelProcessedData = func.laravelConvertItinerary(input, options, format)
        laravelProcessedData
          .then(res => {
            console.log(res.data);
            if (!res.data.flightData.flights[0]) {
              this.setState({
                error: true,
                loading: false
              });
            } else {
              this.setState({
                input: "",
                laravelResponse: res.data.flightData.flights,
                loading: false,
                names,
                error: false
              });
            }
          })
          .catch(err => {
            this.setState({
              error: true,
              loading: false
            });
          });
      }
    );
  };

  render() {
    const {
      options,
      format,
      input,
      loading,
      showSignUp,
      names,
      error,
      laravelResponse,
      fare,
      tax,
      fee,
      fareCurrency,
      adtQty
    } = this.state;
    const {pasteIntoBox} = this;
    console.log(adtQty);
    return (
      <div className="App body">
        <LanguageContext.Consumer>
          {value => (
            <div className={'content-wrapper'}>
              <Helmet>
              <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons"/>
                <meta
                  name="description"
                  content={`${translateFunc(value, "page.meta")}`}
                />
                
              </Helmet>
              {/* <Header user={this.props.user} setTokenInStorage={this.props.setTokenInStorage} logUserOut={this.props.logUserOut}/>
              <Nav value={value} /> */}
              {/* <Banner /> */}

              <form className="container" onSubmit={this.handleSubmit}>
                <div className="floatLeft">
                  <FormEntry
                    setInput={this.setInput}
                    input={input}
                    value={value}
                    fare={fare}
                    fee={fee}
                    tax={tax}
                    fareCurrency={fareCurrency}
                    pasteIntoBox={pasteIntoBox}
                    adtQty={adtQty}
                  />
                  <AdvertisingBox {...this.state} />
                  {loading && <Loader />}
                  {error && <InvalidInput />}
                  {value && !laravelResponse[0] && <Blurb />}
                  {laravelResponse && !error && format !== "tableoutput" && (
                    <ResultsBoxThreeLines
                      // results={processedData}
                      laravelResults={laravelResponse}
                      options={options}
                      format={format}
                      value={value}
                      names={names}
                      fare={fare}
                      fee={fee}
                      tax={tax}
                      fareCurrency={fareCurrency}
                      adtQty={adtQty}
                    />
                  )}

                  {laravelResponse && format === "tableoutput" && (
                    <ResultsTable
                    laravelResults={laravelResponse}
                      // results={processedData}
                      options={options}
                      value={value}
                      names={names}
                      fare={fare}
                      fee={fee}
                      tax={tax}
                      fareCurrency={fareCurrency}
                    />
                  )}
                </div>

                <div className="floatRight">
                  <FormOptions
                    options={options}
                    changeOptions={this.changeOptions}
                    format={format}
                    setFormat={this.setFormat}
                  />
                </div>
              </form>

              {showSignUp && <Gdpr GDPRaccept={this.GDPRaccept} />}
              <Adblock />
            </div>
          )}
        </LanguageContext.Consumer>
      </div>
    );
  }
}

export default MainScreen;
