import React from 'react';
import translateFunc from '../translations/TranslateFunction';

const Names = (props) => {
  return (
    <div className="names-box">
      <b>{translateFunc(props.value, "passenger.itin")}:</b>
      {props.names.map((name)=>{
        return <p key={name}>{name}<br/></p>;
      })}
    </div>
  );
};

export default Names;