import React, { Component, useState, useEffect, useRef } from "react";
import AdSense from "react-adsense";


const AdvertisingBox = () => {

  const [id, setId] = useState(1)

  const divRef = useRef(null)

  const atOptions = {
    key: 'd31fce72c71dc38e997e132a99e2d255',
    format: 'iframe',
    height: 90,
    width: 728,
    params: {},
}

  useEffect(()=>{
  //   console.log('divref', divRef.current)
  //   if (divRef.current && !divRef.current.firstChild) {
  //     const conf = document.createElement('script')
  //     const script = document.createElement('script')
  //     script.type = 'text/javascript'
  //     script.src = `//www.highperformancedformats.com/${atOptions.key}/invoke.js`
  //     conf.innerHTML = `atOptions = ${JSON.stringify(atOptions)}`

  //     divRef.current.append(conf)
  //     divRef.current.append(script)
  // }

  },[divRef])
  // state = {
  //   advertText: "not loaded",
  //   number: 1
  // };

  // inputRef = React.createRef();

  // componentDidMount(){
  //   console.log('1122',this.inputRef)
  // }

  // componentDidUpdate(prevProps, prevState) {
  //   // if (prevProps !== this.props) {
  //   //   let newNumber = Math.random();
  //   //   this.setState({
  //   //     number: newNumber
  //   //   });
  //   // }
  // }

  // render() {
    // let { number } = this.state;
    return (
      <div className="advertisingBox" key={id} id={String(id)} ref={divRef}>

        <AdSense.Google
          key={'number'}
          client="ca-pub-2303157713889417"
          slot="8744366555"
          style={{ width: "728px", height: "90px", display: "inline-block" }}
          format=""
        />

      </div>
    );
  }
// }

export default AdvertisingBox;
