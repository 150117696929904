import React from "react";
import { Helmet } from "react-helmet";

const Api_introduction = () => {
  let requestUrl = `https://api.pnrconverter.com/api`;
  return (
    <div className="api-intro-container">


    <div className="blurb api-intro">
      <Helmet>
        <title>{`API for Easy PNR Converter`}</title>
        <meta
          name="description"
          content="An Introduction to PNR Converter's API"
        />
      </Helmet>

      <h2 className="section-title">Welcome to the New PNR Converter API <span> - Click "Sign up to our API" box above to get going!</span></h2>
      <p>
        We've been working hard and are finally ready to release the PNR
        Converter API. The cheapest, most detailed PNR Conversion API out there!
      </p>
      <h3>What makes our API different?</h3>
      <p>
        While there are other PNR Conversion API's out there, we're confident we
        have made the best! Our detailed API delivers numerous flight statistics
        when passed a standard GDS PNR. A request to the PNR Converter API will
        return the following:
      </p>

      <h4>Arrival & Deparutre Airport Information:</h4>
      <p>Airport Name</p>
      <p>City Name</p>
      <p>County</p>
      <p>Airport Iata Code</p>
      <p>airport location, latitude & longitude</p>
      <p>airport time zone</p>
      <h4>Flight Segment Information</h4>
      <p>Flight Number</p>
      <p>Airline Iata Code</p>
      <p>Airline Name</p>
      <p>Cabin - Either Economy, Premium Economy Business, or First - Major airlines only</p>
      <p>Booking Class</p>
      <p>Aircraft Type - When 3 letter aircraft is provided in the PNR</p>
      <p>Departure time, date & day</p>
      <p>Arrival time, date & day</p>
      <p>Transit time, minutes, hours, days & months</p>
      <p>Flight Duration in minutes & hours</p>
      <p>Flight Distance in miles & KM</p>
      <p>High Res flight logo in SVG format</p>
      <p>Low res but email safe flight logo in PNG format</p>
      <h4>For example...</h4>
      <p>If you were to send this PNR to the PNR Converter API</p>

      <pre className="json-response">
      {`1 VS8020 M 15JAN 2 BOMLHR HK1 2 235A 700A 77W E0 R 
OPERATED BY JET AIRWAYS 
2 VS4041 M 15JAN 2 LHRMSP HK1 3 1200P 315P 76W E0 L 
OPERATED BY DELTA AIRLINES 
`} 
      </pre>
<p>You would get the following response...</p>
      <pre className="json-response">
        {`{
    "flightData": {
        "info": [
            {
                "agencyName": "PNR Converter",
                "requestsLeft": 99999
            }
        ],
        "names": [],
        "flights": [
            {
                "dep": {
                    "airportname": "Chhatrapati Shivaji Airport",
                    "cityname": "Mumbai",
                    "countryname": "India",
                    "airportcode": "BOM",
                    "latitude": "19.088699340",
                    "longitude": "72.867897030",
                    "timezone": "Asia/Calcutta"
                },
                "arr": {
                    "airportname": "London Heathrow Airport",
                    "cityname": "London",
                    "countryname": "United Kingdom",
                    "airportcode": "LHR",
                    "latitude": "51.470600000",
                    "longitude": "-0.461941000",
                    "timezone": "Europe/London"
                },
                "flt": {
                    "flightNo": "8020",
                    "iatacode": "VS",
                    "name": "Virgin Atlantic",
                    "operated_by": "Jet Airways",
                    "cabin": "Economy",
                    "class": "M",
                    "aircraft": "Boeing 777-300ER ",
                    "departure": {
                        "string": "2020-01-15 02:35",
                        "day": "Wed"
                    },
                    "arrival": {
                        "string": "2020-01-15 07:00",
                        "day": "Wed"
                    },
                    "transit_time": {
                        "minutes": 0,
                        "hours": 5,
                        "days": 0,
                        "months": 0
                    },
                    "duration": {
                        "minutes": "55",
                        "hours": "9"
                    },
                    "distance": {
                        "miles": 4484,
                        "km": 7217
                    },
                    "co2": {
                        "co2": 5.6,
                        "co2_with_environmental_impact": 10.59
                    },
                    "svg-logo-high-res": "https://www.pnrconverter.com/images/airlines/vs.svg",
                    "png-logo-low-res": "https://www.pnrconverter.com/images/airlines/png/150/vs.png"
                }
            },
            {
                "dep": {
                    "airportname": "London Heathrow Airport",
                    "cityname": "London",
                    "countryname": "United Kingdom",
                    "airportcode": "LHR",
                    "latitude": "51.470600000",
                    "longitude": "-0.461941000",
                    "timezone": "Europe/London"
                },
                "arr": {
                    "airportname": "Minneapolis Saint Paul Airport",
                    "cityname": "Minneapolis",
                    "countryname": "United States",
                    "airportcode": "MSP",
                    "latitude": "44.881999970",
                    "longitude": "-93.221801760",
                    "timezone": "America/Chicago"
                },
                "flt": {
                    "flightNo": "4041",
                    "iatacode": "VS",
                    "name": "Virgin Atlantic",
                    "operated_by": "Delta Airlines",
                    "cabin": "Economy",
                    "class": "M",
                    "aircraft": "Boeing 767-300",
                    "departure": {
                        "string": "2020-01-15 12:00",
                        "day": "Wed"
                    },
                    "arrival": {
                        "string": "2020-01-15 15:15",
                        "day": "Wed"
                    },
                    "transit_time": {},
                    "duration": {
                        "minutes": "15",
                        "hours": "9"
                    },
                    "distance": {
                        "miles": 4281,
                        "km": 6890
                    },
                    "co2": {
                        "co2": 5.35,
                        "co2_with_environmental_impact": 10.11
                    },
                    "svg-logo-high-res": "https://www.pnrconverter.com/images/airlines/vs.svg",
                    "png-logo-low-res": "https://www.pnrconverter.com/images/airlines/png/150/vs.png"
                }
            }
        ]
    }
}`}
      </pre>

      <h2 className="section-title">How to Use the PNR Converter API</h2>
      <p>
        First of all you'll need to sign up for a free PNR Converter API
        account. Once you've signed up you'll be provided with two keys, a
        public key and a private key. These keys are unque to your account and
        should be kept private
      </p>
      <p>
        In order to use PNR Converter API you'll need to send these keys as
        headers in a post request. You'll need to send three items in the header
        of any request as per below, don't include the square brackets
      </p>

      <p>Content-Type: application/x-www-form-urlencoded</p>
      <p>PUBLIC_APP_KEY: [your public app key]</p>
      <p>PRIVATE_APP_KEY: [your private app key]</p>
      <p>
        Then in the body of the request you'll need to send through the PNR with
        the key of pnr
      </p>
      <p>
        If this dosn't make sense then you might want to ask a developer for
        advice, or alternitively follow the tutorial below on how to use our API
        with <a href="https://www.getpostman.com/">Postman</a>
      </p>
      <h4>Step 1</h4>
      <p>If you havn't already, then download postman using the above link!</p>
      <p>Open up Postman and you should be see something like the following;</p>
      <img src="./images/howto/postman.jpg" alt="postman welcome screen" />
      <h4>Step 2</h4>
      <p>Get the request type to 'POST'</p>
      <img src="./images/howto/postrequest.jpg" alt="postman post request" />
      <p>Under 'Enter Request URL' enter {requestUrl}</p>
      <h4>Step 3</h4>
      <p>
        Under Headers tab, you'll need to send three headers to PNR Converter.
        This is your authentication, so we know who's sending us requests
      </p>
      <p>Content-Type: application/x-www-form-urlencoded</p>
      <p>PUBLIC_APP_KEY: [your public app key]</p>
      <p>PRIVATE_APP_KEY: [your private app key]</p>
      <p>For Example</p>
      <img src="./images/howto/headers.jpg" alt="postman post request" />
      <h4>Step 4</h4>
      <p>
        You'll now need to send us the PNR. Under the 'Body' tab, enter a key of
        'pnr', and then for the value, enter your PNR
      </p>
      <img src="./images/howto/pnrbody.jpg" alt="postman body request" />
      <h4>Step 5</h4>
      <p>Hit Send</p>
      <h4>Step 6</h4>
      <p>
        Enjoy a nicely formatted JSON response with a breakdown of all flight
        and passenger details*. Passenger details currently in development
      </p>
      <pre className="json-response">
        {`{
  "flightData": {
    "info": [
      {
      "agencyName": "PNR Converter",
      "requestsLeft": 9999
      }
      ],
    "names": [],
    "flights": [
      {
      "dep": {
        "airportname": "Incheon Airport",
        "cityname": "Seoul",
        "countryname": "South Korea",
        "airportcode": "ICN",
        "latitude": "37.469100950",
        "longitude": "126.450996400",
        "timezone": "Asia/Seoul"
      },
        "arr": {
        "airportname": "Kuala Lumpur Intl Airport",
        "cityname": "",
        "countryname": "Malaysia",
        "airportcode": "KUL",
        "latitude": "2.745579958",
        "longitude": "101.709999100",
        "timezone": "Asia/Kuala_Lumpur"
      },
      "flt": {
        "flightNo": "67",
        "iatacode": "MH",
        "name": "Malaysia Airlines",
        "operated_by": "Malaysia Airlines",
        "cabin": "Economy",
        "class": "Y",
        "aircraft": "Airbus A380",
        "departure": {
        "string": "2020-01-05 11:00",
        "day": "Sun"
      },
      "arrival": {
        "string": "2020-01-05 16:45",
        "day": "Sun"
      },
      "transit_time": {
        "minutes": 35,
        "hours": 5,
        "days": 0,
        "months": 0
      },
      "duration": {
        "minutes": "45",
        "hours": "6"
      },
      "distance": {
        "miles": 2841,
        "km": 4572
      },
      "svg-logo-high-res": "https://www.pnrconverter.com/images/airlines/mh.svg",
      "png-logo-low-res": "https://www.pnrconverter.com/images/airlines/png/150/mh.png"
      }
      },
      {
      "dep": {
        "airportname": "Kuala Lumpur Intl Airport",
        "cityname": "",
        "countryname": "Malaysia",
        "airportcode": "KUL",
        "latitude": "2.745579958",
        "longitude": "101.709999100",
        "timezone": "Asia/Kuala_Lumpur"
      },
      "arr": {
        "airportname": "Adelaide Intl Airport",
        "cityname": "Adelaide",
        "countryname": "Australia",
        "airportcode": "ADL",
        "latitude": "-34.944999690",
        "longitude": "138.531005900",
        "timezone": "Australia/Adelaide"
      },
      "flt": {
        "flightNo": "139",
        "iatacode": "MH",
        "name": "Malaysia Airlines",
        "operated_by": "Malaysia Airlines",
        "cabin": "Economy",
        "class": "Y",
        "aircraft": "Airbus A330-300",
        "departure": {
        "string": "2020-01-05 22:20",
        "day": "Sun"
      },
      "arrival": {
        "string": "2020-01-06 08:00",
        "day": "Mon"
      },
      "transit_time": {},
        "duration": {
        "minutes": "10",
        "hours": "7"
      },
      "distance": {
        "miles": 3475,
        "km": 5593
      },
      "svg-logo-high-res": "https://www.pnrconverter.com/apiimg/airlines/mh.svg",
      "png-logo-low-res": "https://www.pnrconverter.com/apiimg/airlines/png/150/mh.png"
      }
      }
    ]
  }
}`}
      </pre>
    </div>
    </div>
  );
};

export default Api_introduction;
